<template>
  <div>
    <div v-if="storefronts && storefronts.length" class="position-table">
      <md-table v-model="storefronts">
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="ID" class="custom-field">
            <span>{{ item.id }}</span>
          </md-table-cell>

          <md-table-cell md-label="Название" class="custom-field">
            <span :title="item.title">{{ item.title }}</span>
          </md-table-cell>

          <md-table-cell md-label="Иконка" class="custom-field">
            <img class="cover-image" :src="item.cover" alt="Icon" />
          </md-table-cell>

          <md-table-cell>
            <div class="edit-and-delete-block">
              <md-button
                class="md-just-icon md-simple md-primary"
                :to="`/storefronts/${item.id}`"
              >
                <md-icon>edit</md-icon>
                <md-tooltip md-direction="top">Редактировать</md-tooltip>
              </md-button>
              <md-button
                class="md-just-icon md-simple md-danger"
                @click="selectForRemove(item.id)"
              >
                <md-icon>delete</md-icon>
                <md-tooltip md-direction="top">Удалить</md-tooltip>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
    <div v-else class="my-empty empty">Пусто</div>

    <ModalConfirm
      v-if="showModal"
      text="Удалить витрину?"
      @confirm="deleteStorefront"
      @close="showModal = false"
    />
  </div>
</template>

<script>
import ModalConfirm from "@/components/Modals/Confirm";

import { mapState, mapActions } from "vuex";

export default {
  components: {
    ModalConfirm,
  },

  data() {
    return {
      selectedId: null,
      showModal: false,
    };
  },

  computed: {
    ...mapState({
      storefronts: (state) => state.storefronts.storefronts,
    }),
  },

  methods: {
    ...mapActions("storefronts", ["getStorefronts", "removeStorefront"]),

    async deleteStorefront() {
      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.removeStorefront(this.selectedId);
      if (success) {
        await this.getStorefronts();
        this.selectedId = null;
        this.showModal = false;
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },

    selectForRemove(id) {
      this.selectedId = id;
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-field {
  max-width: 150px;

  &::v-deep {
    .md-table-cell-container {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.cover-image {
  max-width: 100%;
  max-height: 100px;
  object-fit: cover;
}
</style>
