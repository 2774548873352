<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Список витрин</h4>
          </div>
        </md-card-header>

        <md-card-content>
          <StorefrontsTable />
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import StorefrontsTable from "@/components/Tables/StorefrontsTable";

import { mapActions } from "vuex";

export default {
  components: {
    StorefrontsTable,
  },

  async mounted() {
    this.$store.commit("SET_SHOW_LOADER", true);
    await this.getStorefronts();
    this.$store.commit("SET_SHOW_LOADER", false);
  },

  methods: {
    ...mapActions("storefronts", ["getStorefronts"]),
  },
};
</script>

<style lang="scss" scoped>
.md-layout {
  max-width: 100%;
}
</style>